<template>
  <div
    class="single-table-row"
    :class="{ 'is-content': !ticketData.isHeading }"
    ref="singleTableRow"
  >
    <span>{{ ticketData.frm_id }}</span>
    <span>{{ ticketData.frm_name }}</span>
    <span>{{ ticketData.frm_lastname }}</span>
    <span
      v-if="!hideUnnecessaryData"
      class="adress"
      :class="{ bold: !ticketData.isHeading }"
      >{{ ticketData.frm_address }}</span
    >
    <span v-if="!hideUnnecessaryData">{{ ticketData.frm_place }}</span>
    <span v-if="!hideUnnecessaryData">
      <span class='present' v-if="ticketData.frm_gift=='Poklon'">Poklon</span>
      <span class='present' v-else>{{gift.gift_name}}</span>
    </span>
    <span v-if="!hideUnnecessaryData">{{ ticketData.frm_phone }}</span>
    <span v-if="!hideUnnecessaryData">{{ ticketData.pro_name ? ticketData.pro_name : "Uređaj" }}</span>
    <span :class="{ bold: !ticketData.isHeading }">{{
      ticketData.imi_imei
    }}</span>
  </div>
</template>

<script>
export default {
  props: {
    ticketData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    hideUnnecessaryData: {
      type: Boolean,
    },
  },
  data() {
    return {
      displayAll: true,
    };
  },
  mounted() {
    if (!this.ticketData.isHeading) {
      console.log(this.ticketData);
      this.$refs.singleTableRow.addEventListener("click", this.openModal);
    }
  },
  methods: {
    openModal() {
      this.$emit("showModal", true);
      this.$emit("passTicketData", this.ticketData);
    },
  },
};
</script>

<style lang='scss' scoped>
.single-table-row {
  padding: 20px 40px;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  position: relative;
  &>span {
    display: inline-block;
    width: calc(100% / 8);
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    color: #394050;
  }
  .bold {
    font-weight: bold;
  }
  .adress {
    min-width: 300px;
  }
  .zip-code {
    font-style: italic;
  }
  .present {
    text-align: center;
    display: inline-block;
    width: 100px;
  }
}
.is-content {
  &:hover {
    background: #fbece2;
    &::after {
      content: "";
      height: 100%;
      width: 4px;
      background: #f96600;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}
</style>